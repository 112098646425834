import { IconButton } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const ResourceEntry = ({ file, handleOpen }) => {

    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={() => handleOpen(file.url)}>
            <a href={file.url} target="_blank" rel="noopener noreferrer" >
                <IconButton style={{position: 'absolute', top: '6px', right: '10px', padding: '0', fontSize: '20px'}} aria-label="open" size="large" alt='open'>
                    <LaunchIcon color="black" fontSize="inherit" onClick={e => e.stopPropagation()} />
                </IconButton>
            </a>
            <p style={{fontWeight: "600", textAlign: 'center', padding: '0 25px'}}>
                {file.filename.split('/')[1].split('.')[0]}
            </p>
            <p>
                Category: {file.type} 
                {file.type === 'competition' &&
                    ' | ' + file.series
                }
            </p>

        </div>
    );
};

export default ResourceEntry;