import { Button } from "@mui/material";
import React from "react";

const ContentViewer = ({ handleClose, contentUrl }) => {

    return (
        <>
            {/* Overlay */}
            <div
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    right: "0",
                    height: "100%",
                    backgroundColor: "rgba(84, 84, 84, 0.7)",
                    zIndex: "999",
                }}
            ></div>
            
            {/* Content Viewer */}
            <div
                style={{
                    backgroundColor: "#fff",
                    border: "2px solid #545454",
                    borderRadius: "15px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "100%",
                    width: "100%",
                    zIndex: "1000",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >                
                {/* Iframe for YouTube or PDF */}
                <iframe
                    src={contentUrl}
                    title={"Content Viewer"}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                    allow="fullscreen"
                ></iframe>
                
                {/* Close Button */}
                <div style={{ marginTop: "10px" }}>
                    <Button
                        style={{
                            margin: "5px",
                            width: "100px",
                            backgroundColor: "#83FFE7",
                            color: "#545454",
                            fontWeight: "bold",
                        }}
                        onClick={handleClose}
                        variant="contained"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ContentViewer;
